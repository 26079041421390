import React from "react";
import Lottie from "react-lottie";

export default () => {

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: require("../animations/christmas-tree.json"),
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
        <div className="absolute bottom-0 right-0 w-full" style={{zIndex:200}}>
      <Lottie
        isClickToPauseDisabled={true}
        options={defaultOptions}
        width={"100%"}
        height={"auto"}
      />
      </div>
    );
  
}
