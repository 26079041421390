import React, { useState } from "react"

export default ({ cb, audioReadyCount }) => {
  const [epilepsy, setEpilepsy] = useState(false)
  return (
    <div>
      <div className="bg-teal-100 p-5 rounded text-teal-800 text-center mb-5 divide-y-4 divide-teal-200">
        <h1 className="text-3xl mb-2 font-bold">Sam's Jukebox</h1>
        <h4 className="text-xl my-2 pt-2">
          This site plays music but I just wanted to check that was okay with
          you first. The site also features flashing images -{" "}
          <strong>
            if you have epilepsy, toggle the box below and I will disable them
          </strong>
          .
        </h4>
      </div>

      <div className="flex items-center mb-5">
        <label htmlFor="toogleA" className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              id="toogleA"
              type="checkbox"
              className="hidden"
              onChange={() => setEpilepsy(!epilepsy)}
            />

            <div className="toggle__line w-12 h-6 bg-gray-400 rounded-full shadow-inner"></div>

            <div className="toggle__dot absolute w-8 h-8 bg-white rounded-full shadow inset-y-0 left-0"></div>
          </div>

          <div className="ml-6 text-xl">
            Flashing images are{" "}
            <strong>{epilepsy ? "disabled" : "enabled"}</strong>.
          </div>
        </label>
      </div>
      <button
        className="btn w-full mx-auto text-xl mb-2"
        onClick={() => cb(epilepsy)}
      >
        <i className="las la-music text-2xl"></i> Lets make some music!
      </button>
      {audioReadyCount > 1 && (
        <h3 className="text-center">
          {audioReadyCount} people already music ready!
        </h3>
      )}
    </div>
  )
}
