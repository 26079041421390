import React from "react"
import Permissions from "../components/Permissions"
import Status from "../components/Status"
import colors from "../data/colors.json"
import Layout from "../components/Layout"
import { connect } from "react-redux"
import Animation from "../components/Animation"
function App({
  permission,
  count,
  audioReadyCount,
  track,
  currentNote,
  color,
  epilepsy,
  connected,
  part,
  retrievedAudio,
  isPlaying,
  epilepsyToggle,
  permissionsRecieved,
}) {
  // const changeColor = newNote => {
  //   if (newNote !== currentNote) {
  //     setColor(Math.floor(Math.random() * Math.floor(colors.length)))
  //   }
  //   setCurrentNote(newNote)
  // }

  async function takeAllThePermissionsYouNeed(epilepsy) {
    permissionsRecieved()
    if (epilepsy) {
      epilepsyToggle()
    }
    // assembleSampler()
  }

  return (
    <Layout>
      {permission ? (
        <div className="w-full h-full">
          {!isPlaying ? (
            <>
              <Status
                epilepsy={epilepsy}
                permission={permission}
                connected={connected}
                part={part}
                retrievedAudio={retrievedAudio}
                count={count}
                track={track}
              />
            </>
          ) : (
            <div className="relative w-full h-full">
              <Animation />

              <div
                className="rounded p-5 text-center w-full h-full flex justify-center"
                style={{
                  backgroundColor: `#${colors[color]}`,
                }}
              >
                <h1 className="text-5xl font-bold">{currentNote}</h1>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Permissions
            cb={takeAllThePermissionsYouNeed}
            audioReadyCount={audioReadyCount}
          />
        </div>
      )}
    </Layout>
  )
}

const mapStateToProps = ({
  permission,
  count,
  audioReadyCount,
  track,
  currentNote,
  color,
  epilepsy,
  connected,
  part,
  retrievedAudio,
  isPlaying,
}) => {
  return {
    permission,
    count,
    audioReadyCount,
    track,
    currentNote,
    color,
    epilepsy,
    connected,
    part,
    retrievedAudio,
    isPlaying,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    epilepsyToggle: () => dispatch({ type: "epileptic" }),
    permissionsRecieved: () => dispatch({ type: "permissions" }),
    subbed: () => dispatch({ type: "server/subbed" }),
  }
}

const ConnectedApp =
  typeof window !== `undefined`
    ? connect(mapStateToProps, mapDispatchToProps)(App)
    : App

export default ConnectedApp
