import React from "react"
import Spinner from "./Spinner"
export default ({
  epilepsy,
  permission,
  connected,
  part,
  retrievedAudio,
  count,
  track,
}) => {
  return (
    <div className="grid grid-cols-1 gap-4">
      {epilepsy && (
        <div className="flex items-center rounded text-teal-800 justify-center text-center">
          <i className="text-xl las la-camera-retro mr-1"></i>
          <h1 className="text-sm">
            Flashing Images <strong>disabled</strong>.
          </h1>
        </div>
      )}
      <div className="flex items-center rounded text-teal-800 justify-center">
        <h1 className="text-sm">
          For the best experience make sure your device is <strong>not</strong>{" "}
          on silent. You should also increase your brightness and volume to at
          least 75%.
        </h1>
      </div>

      {permission && (
        <div className="flex items-center bg-green-200 p-5 rounded text-teal-800 justify-center text-center">
          <i className="text-2xl las la-mobile"></i>
          <h1 className="text-xl ml-2">Device ready to play audio.</h1>
        </div>
      )}
      {permission && connected ? (
        <div className="flex items-center bg-green-200 p-5 rounded text-teal-800 justify-center text-center">
          <i className="text-2xl las la-globe"></i>
          <h1 className="text-xl ml-2">You're connected to the server!</h1>
        </div>
      ) : (
        <div className="flex items-center text-indigo-800 bg-indigo-200 p-5 rounded justify-center text-center">
          <i className="text-2xl las la-search animate-bounce"></i>
          <h1 className="text-xl ml-2">Connecting to the server...</h1>
        </div>
      )}
      {permission && connected && part && (
        <div className="bg-teal-200 p-5 rounded text-teal-800 text-center">
          <h1 className="text-xl">
            Your device has been assigned to the <strong>{part}</strong> part.
          </h1>
        </div>
      )}
      {permission && connected && part && retrievedAudio ? (
        <div className="flex items-center bg-green-200 p-5 rounded text-teal-800 justify-center text-center">
          <i className="text-2xl las la-check-circle"></i>
          <h1 className="text-xl ml-2">Generated device sampler.</h1>
        </div>
      ) : (
        <div className="flex items-center text-indigo-800 bg-indigo-200 p-5 rounded justify-center text-center">
          <i className="text-2xl las la-arrow-down animate-bounce"></i>
          <h1 className="text-xl ml-2">Pulling required sampler files...</h1>
        </div>
      )}
      {permission &&
      connected &&
      part &&
      retrievedAudio &&
      track.length !== 0 ? (
        <div className="flex items-center bg-green-200 p-5 rounded text-teal-800 justify-center text-center">
          <i className="text-2xl las la-music"></i>
          <h1 className="text-xl ml-2">Got the music notes.</h1>
        </div>
      ) : (
        <div className="flex items-center text-indigo-800 bg-indigo-200 p-5 rounded justify-center text-center">
          <i className="text-2xl las la-arrow-down animate-bounce"></i>
          <h1 className="text-xl ml-2">Waiting to recieve music...</h1>
        </div>
      )}
      {permission && connected && part && retrievedAudio && track.length !== 0 && (
        <button
          type="button"
          className="inline-flex items-center justify-center w-full p-5 border border-transparent text-xl rounded text-indigo-800 bg-indigo-200"
          disabled=""
        >
          <Spinner />
          Waiting for conductor...
        </button>
      )}
      {count && (
        <div className="flex items-center rounded text-teal-800 justify-center text-center">
          <h1 className="text-sm">
            You're among <strong>{count} people</strong>.
          </h1>
        </div>
      )}
    </div>
  )
}
